import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {BuildingEquipmentModel} from '../../deprecated-models/deprecated-equipments/building-equipment.model';
import {EXPORT_CSV, QRCODE} from '../../deprecated-models/deprecated-equipments/equipment-models-paths.enum';
import {EquipmentPopulatedModel} from "../../deprecated-models/deprecated-equipments/deprecated-populated-equipment/equipment-populated.model";
import {TranslatedToasterService} from "../../../services/translated-toaster/translated-toaster.service";
import {AuthenticationsService} from "../../../services/auth/authenticationsService";
import {SitesEquipmentsPaginationModel} from "../../../models/site-equipments/site-equipments-pagination";
import {SiteEquipmentModel} from "../../../models/site-equipments/site-equipment.model";

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class BuildingEquipmentService {
  private buildingsEquipmentsPath = `api/sg-equipment/building-equipment/`;
  private equipmentsPath = `api/sg-equipment/equipments/`;

  private buildingsEquipmentsListPath = 'list';

  constructor(private http: HttpClient, private authService: AuthenticationsService, private translatedToaster: TranslatedToasterService) {
  }

  /**
   * @deprecated
   */
  createBuildingEquipment(body: SiteEquipmentModel) {
    const path = this.buildingsEquipmentsPath;
    return this.http.post(path, body).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildingEquipmentById(buildingEquipmentId, populate = false): Observable<any> {
    const url = this.buildingsEquipmentsPath + buildingEquipmentId;
    let params = new HttpParams();
    if (populate) {
      params = params.append('populate', 'true')
    }
    return this.http.get(url, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildingEquipmentQrCodeById(buildingEquipmentId): Observable<any> {
    const url = this.buildingsEquipmentsPath + QRCODE + buildingEquipmentId;
    return this.http.get(url).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildingEquipmentPdf(buildingEquipmentId: string) {
    const path = this.buildingsEquipmentsPath + 'pdf/';
    let params = new HttpParams({fromObject: {buildingEquipmentsIds: [buildingEquipmentId]}});
    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  exportBuildingEquipments(filter: SitesEquipmentsPaginationModel, header) {
    let params = new HttpParams();
    delete filter['pageIndex'];
    delete filter['pageSize'];
    params = params.append('filter', JSON.stringify(filter));
    params = params.append('header', JSON.stringify(header));

    const path = this.buildingsEquipmentsPath + EXPORT_CSV;
    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }


  /**
   * @deprecated
   */
  getBuildingEquipmentsPdf(filters: SitesEquipmentsPaginationModel, buildingId: string) {
    const path = this.buildingsEquipmentsPath + 'pdf/';
    let params = new HttpParams({fromObject: {buildingsIds: [buildingId]}});
    // ^ is regex to match pattren with first values for other string
    delete filters['pageIndex'];
    delete filters['pageSize'];
    params = params.append("filters", JSON.stringify(filters));
    params = params.append("qureyType", "and");
    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildingEquipmentsListFields(listFields: any) {
    const path = this.buildingsEquipmentsPath;
    let params = new HttpParams();
    params = params.append("listFields", JSON.stringify(listFields));
    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  searchBuildingEquipment(pattern: string) {
    const path = this.buildingsEquipmentsPath;
    let params = new HttpParams();
    if (pattern) {
      params = params.append("pattern", pattern);
    }
    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  getBuildingEquipmentsByBuildingId(
    buildingId: string,
    populate = false,
    listFields?: Object,
    pattern?: string,
    paginate?: { size: number, index: number }
  ): Observable<any> {
    let params = new HttpParams({fromObject: {buildingsIds: [buildingId]}});
    if (populate) {
      params = params.append("populate", JSON.stringify(populate));
    }
    if (listFields) {
      params = params.append("listFields", JSON.stringify(listFields));
    }
    if (pattern) {
      params = params.append("pattern", pattern);
    }
    if (paginate) {
      params = params.append("paginate", JSON.stringify(paginate));
    }

    let url = this.buildingsEquipmentsPath;
    return this.http.get(url, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  filterBuildingEquipments(
    filters: { buildingsIds?: string[], categoriesIds?: string[], nomenClature?: string, localization?: string[], floorIndex?: string, isControl?: boolean },
    populate = false,
    listFields?: Object,
    paginate?: { size: number, index: number }
  ): Observable<any> {
    const body = {
      populate: JSON.stringify(populate),
      listFields: JSON.stringify(listFields),
      paginate: JSON.stringify(paginate),
      filters: filters,
    }

    let url = this.buildingsEquipmentsPath + this.buildingsEquipmentsListPath;
    return this.http.post(url, body).pipe(
      catchError(this.handleError)
    );
  }


  /**
   * @deprecated
   */
  getBuildingEquipmentByBuildingId(buildingId, scada?: boolean, control?: boolean) {
    const path = this.buildingsEquipmentsPath + 'by-buildingId';
    let params = new HttpParams();
    params = params.append("buildingId", JSON.stringify(buildingId));
    params = params.append("scada", JSON.stringify(scada));
    params = params.append("control", JSON.stringify(control));

    return this.http.get(path, {params}).pipe(

      catchError(this.handleError)
    );
  }

  /**
   * @deprecated
   */
  updateBuildingEquipment(body: BuildingEquipmentModel, buildingEquipmentId) {
    const path = this.buildingsEquipmentsPath + buildingEquipmentId;
    return this.http
      .patch(path, body)
      .pipe( catchError(this.handleError));
  }

  /**
   * @deprecated
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError({
        error: error.error.message,
        statuscode: error.status
      });
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError({error: 'backend error', statuscode: error.status});
    }
    // return an observable with a user-facing error message
  }

}
