export namespace DeviceOperationEnum {

  export type DeviceOperation =
    "POWER" |
    "MODE" |
    "TEMPERATURE_SET_POINT" |
    "SWING_POSITION_VERTICAL" |
    "FAN_SPEED" |
    "CURSOR" |
    "VALUE" |
    "STATUS" |
    "CURRENT_STATUS" |
    "SCROLLING_MENU" |
    "UNIFIED_SCROLLING_MENU" |
    "ACTION" |
    "DISPLAY_VALUE" |
    "SWITCHABLE_LIGHT";


    export const DeviceOperationEnum = {
      POWER: "POWER" as DeviceOperation,
      MODE : "MODE" as DeviceOperation,
      TEMPERATURE_SET_POINT: "TEMPERATURE_SET_POINT" as DeviceOperation,
      SWING_POSITION_VERTICAL: "SWING_POSITION_VERTICAL" as DeviceOperation,
      FAN_SPEED: "FAN_SPEED" as DeviceOperation,
      CURSOR: "CURSOR" as DeviceOperation,
      VALUE: "VALUE" as DeviceOperation,
      STATUS: "STATUS" as DeviceOperation,
      CURRENT_STATUS: "CURRENT_STATUS" as DeviceOperation,
      SCROLLING_MENU: "SCROLLING_MENU" as DeviceOperation,
      UNIFIED_SCROLLING_MENU: "UNIFIED_SCROLLING_MENU" as DeviceOperation,
      ACTION: 'ACTION' as DeviceOperation,
      DISPLAY_VALUE: 'DISPLAY_VALUE' as DeviceOperation,
      SWITCHABLE_LIGHT: 'SWITCHABLE_LIGHT' as DeviceOperation
    }

    export const DeviceOperationsList = [
      DeviceOperationEnum.POWER,
      DeviceOperationEnum.MODE,
      DeviceOperationEnum.TEMPERATURE_SET_POINT,
      DeviceOperationEnum.SWING_POSITION_VERTICAL,
      DeviceOperationEnum.FAN_SPEED,
      DeviceOperationEnum.CURSOR,
      DeviceOperationEnum.VALUE,
      DeviceOperationEnum.STATUS,
      DeviceOperationEnum.CURRENT_STATUS,
      DeviceOperationEnum.SCROLLING_MENU,
      DeviceOperationEnum.UNIFIED_SCROLLING_MENU,
      DeviceOperationEnum.ACTION,
      DeviceOperationEnum.DISPLAY_VALUE,
      DeviceOperationEnum.SWITCHABLE_LIGHT
    ];

}
