import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SgAutocompleteSingleSelectComponent} from "./sg-autocomplete-single-select.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {PipesModule} from "../../../core/pipes/pipes.module";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {LoadingSkeletonModule} from "../../loading-skeleton/loading-skeleton.module";
import { NoSpaceDirective } from './no-space.directive';


@NgModule({
  declarations: [SgAutocompleteSingleSelectComponent, NoSpaceDirective],
  exports: [SgAutocompleteSingleSelectComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    PipesModule,
    TranslateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    LoadingSkeletonModule
  ]
})
export class SgAutocompleteSingleSelectModule {
}
