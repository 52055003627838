import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Injectable({providedIn: 'root'})
export class TranslatedToasterService {
  constructor(private translateService: TranslateService, private toastrService: ToastrService,) {
  }

  showSuccessMessage(message: string) {
    this.toastrService.success(this.translateService.instant(message), null, {
      toastClass: 'ngx-toastr custom-toast'
    })
  }

  showErrorMessage(message: string, param?, paramName?: string) {
    let paramObject = {};
    paramObject[paramName] = param;
    this.toastrService.error(this.translateService.instant(message, paramObject), null, {
      toastClass: 'ngx-toastr custom-toast'
    })
  }

  showErrorCodeMessage(error, paramValue?, translationParamName?: string, translateParam?: boolean) {
    let paramObject = {};
    paramObject[translationParamName] = translateParam ? this.translateService.instant(paramValue) : paramValue;
    let messageKey = error.error ? error.error : error
    this.toastrService.error(this.translateService.instant('ERROR_CODES.' + messageKey, paramObject), null, {
      toastClass: 'ngx-toastr custom-toast'
    });
  }

  showSuccessCodeMessage(key, paramValue?, translationParamName?: string, translateParam?: boolean) {
    let paramObject = {};
    paramObject[translationParamName] = translateParam ? this.translateService.instant(paramValue) : paramValue;
    this.toastrService.success(this.translateService.instant('SUCCESS_CODES.' + key, paramObject), null, {
      toastClass: 'ngx-toastr custom-toast'
    });
  }


  showInfoMessage(message: string) {
    this.toastrService.info(this.translateService.instant(message), null, {
      toastClass: 'ngx-toastr custom-toast'
    })
  }
}
