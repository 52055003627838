<div class="sg-single-select-container bold-500-form-text responsive-mat-form-field">
  <mat-form-field [appearance]="appearance" class="sg-single-select-form-field" [ngStyle]="{'min-width': minWidth}">
    <mat-label>{{ label }}</mat-label>
    <mat-select no-space [formControl]="dataSelectControl" [disabled]="isDisabled"
                panelClass="customized-500-bold-mat-select custom-sg-select-panel" [required]="required">
      <div class="autocomplete-header">
        <mat-form-field appearance="outline">
          <mat-label>{{'FORMS.SEARCH_AND_SELECT' | translate | capitalize}}</mat-label>
          <input matInput type="text" (keyup)="searchSubject$.next($event.target.value)" #searchInputRef>
        </mat-form-field>
      </div>
      <ng-container
        *skeleton="isLoadingData;repeat: 1; component:loadingSkeletonComponent;height: '50px';width: '10px';">
        <mat-option *ngIf="allowAllOption" [value]="'all'" (click)="handleSelect()">
          {{ 'GENERAL.ALL' | translate | capitalize }}
        </mat-option>
        <mat-option *ngFor="let option of filterOptionsList; let i = index"
                    [value]="option[uniqueKey]"
                    (click)="handleSelect(option)">
          <ng-container
            *ngTemplateOutlet="includeLabelTranslation && option.labelTranslation ? translatedLabel : filterKeyLabel context: { option: option}"></ng-container>
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-spinner [diameter]="20" class="internal-spinner" *ngIf="isLoadingData && !isDisabled"></mat-spinner>
  </mat-form-field>
</div>


<ng-template #translatedLabel let-option="option">
  {{ option["labelTranslation"][lang] }}
</ng-template>

<ng-template #filterKeyLabel let-option="option">
  {{ option[filterKey] }}
</ng-template>
